<template>
	<div class="inbox-header with-filters sticky top-0 z-50">
		<div class="inbox-title">
			<span class="inbox-title-label">
				{{ partner ? partner.name : 'Pages' }}
			</span>
			<router-link
				:to="{ name: 'app.paging.create' }"
				class="btn btn-sm"
				data-tooltip="New Page"
				data-position="left"
			>
				<div class="btn-ripple"></div>
				<span class="sr-only">New Page</span>
				<font-awesome-icon
					class="btn-icon"
					:icon="['far', 'plus']"
					aria-hidden="true"
				/>
			</router-link>
		</div>
		<div class="call-filters">
			<pages-filter-dropdown />
			<provider-filter-dropdown is-pages-filter />
		</div>
		<div class="chip-set px-4">
			<chip v-for="item in filters" :key="item" condensed>
				{{ item | toFilterName }}
				<template #trailing>
					<font-awesome-icon
						class="chip-remove"
						:icon="['fal', 'times']"
						@click.prevent.stop="onRemoveFilter(item)"
					/>
				</template>
			</chip>
		</div>
		<div class="flex items-center justify-between mt-2 px-4">
			<button
				type="button"
				aria-label="Sort dates button"
				class="btn-icon-button flex items-center justify-center"
				@click.prevent="$emit('sort')"
			>
				<div class="btn-ripple"></div>
				<font-awesome-icon class="btn-icon" :icon="['fad', sortIcon]" />
			</button>
			<div class="inbox-header-subtitle">
				{{ count || 0 }} / {{ total }} records
			</div>
		</div>
	</div>
</template>
<script>
import Chip from '@/components/Chip'
import { mapActions, mapGetters, mapState } from 'vuex'
import PagesFilterDropdown from './PagesFilterDropdown'
import ProviderFilterDropdown from './ProviderFilterDropdown'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Chip,
		PagesFilterDropdown,
		ProviderFilterDropdown,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the sort icon based on the current sort direction.
		 *
		 * @return {String}
		 */
		sortIcon() {
			if (this.sort === 'asc') {
				return 'sort-amount-down'
			}

			return 'sort-amount-up'
		},

		/**
		 * Get the total number of models available (or total in the store).
		 *
		 * @return {Number}
		 */
		total() {
			if (this.count > this.meta.total) {
				return this.count || 0
			}

			return this.meta.total || 0
		},

		...mapGetters({
			count: 'pages/count',
			meta: 'pages/meta',
			hasFilter: 'pages/hasFilter',
			partner: 'partner',
		}),

		...mapState({
			filters: state => state.pages.filters.statuses,
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Remove a filter and refresh the filtered pages.
		 *
		 * @return {void}
		 */
		async onRemoveFilter(filter) {
			await this.removeFilter(filter)

			if (this.hasFilter) {
				await this.get()
			}
		},

		...mapActions('pages', {
			get: 'get',
			removeFilter: 'removeFilter',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'InboxPagesHeader',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The current sort direction.
		 *
		 * @type {String}
		 */
		sort: {
			type: String,
			default: 'asc',
		},
	},
}
</script>
