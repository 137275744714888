<template>
	<div class="flex" :class="{ 'calls-show': hasSubRoute }">
		<div ref="inbox" class="inbox">
			<inbox-pages-header :sort="sort" @sort="onSort" />
			<div v-for="(date, key) in dates" :key="key" class="inbox-group">
				<div v-if="date === today" class="inbox-group-title">
					<font-awesome-icon :icon="['far', 'calendar-day']" />
					Today
				</div>
				<div v-else-if="date === yesterday" class="inbox-group-title">
					<font-awesome-icon :icon="['far', 'calendar-day']" />
					Yesterday
				</div>
				<div v-else class="inbox-group-title">
					<font-awesome-icon :icon="['far', 'calendar-day']" />

					{{ date }}
				</div>

				<page-card
					v-for="page in groups[date]"
					:key="page.id"
					:page="page"
				/>
			</div>
			<loader
				v-if="hasNextPage && !hasFetchedAllPages"
				:enabled="autoload"
				:threshold="0.65"
				@intersect="fetchNextPage"
				@click.prevent="onLoadMoreClick"
			>
				{{ busy ? 'Please wait...' : 'Load more' }}
				<font-awesome-icon
					v-if="busy"
					class="ml-2"
					:icon="['far', 'circle-notch']"
					spin
				/>
				<font-awesome-icon
					v-else
					class="ml-2"
					:icon="['far', 'chevron-down']"
				/>
			</loader>
		</div>
		<router-view />
	</div>
</template>

<script>
import moment from 'moment'
import { sortBy } from 'lodash'
import Loader from '@/components/Loader'
import PageCard from '@/components/PageCard'
import { mapActions, mapGetters } from 'vuex'
import InboxPagesHeader from '@/components/InboxPagesHeader'

/**
 * The format used to display page dates.
 *
 * @type {String}
 */
const DATE_FORMAT = 'MM/DD/YYYY'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Loader,
		InboxPagesHeader,
		PageCard,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Sort the pages grouped by date/day occured.
		 *
		 * @return {Array}
		 */
		dates() {
			const keys = Object.keys(this.groups)

			return sortBy(keys, [
				date => {
					if (this.sort === 'desc') {
						return this.toUnix(date)
					}

					return this.toUnix(date) * -1
				},
			])
		},

		/**
		 * Get all pages grouped together by date.
		 *
		 * @return {Object}
		 */
		groups() {
			const groups = this.pages.reduce((accu, page) => {
				const formatted = moment
					.utc(page.created_at)
					.local()
					.format(DATE_FORMAT)

				accu[formatted] = (accu[formatted] || []).concat(page)

				return accu
			}, {})

			Object.keys(groups).forEach(key => {
				groups[key] = sortBy(groups[key], ['created_at'])

				if (this.sort === 'asc') {
					groups[key].reverse()
				}
			})

			return groups
		},

		/**
		 * Determine if the another page can be loaded.
		 *
		 * @return {Boolean}
		 */
		hasNextPage() {
			const { current_page, last_page } = this.meta

			return current_page > 0 && current_page < last_page
		},

		/**
		 * Determine if the current page is the pages show route.
		 *
		 * @return {Boolean}
		 */
		hasSubRoute() {
			return ['app.paging.show', 'app.paging.create'].includes(
				this.$route.name
			)
		},

		...mapGetters({
			count: 'pages/count',
			meta: 'pages/meta',
			pages: 'pages/pages',
			partner: 'partner',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Load the inital paging data from the API.
		 *
		 * @return {void}
		 */
		async getData() {
			try {
				await this.get()
			} catch (e) {
				this.$alert.error('default.error')
			}
		},

		/**
		 * Fetch more pages when it's needed.
		 *
		 * @return {void}
		 */
		async fetchNextPage() {
			if (!this.autoload || this.busy) {
				return
			}

			if (!this.hasNextPage) {
				this.hasFetchedAllPages = true
				this.autoload = false
			}

			this.busy = true

			const { scrollTop } = this.$refs.inbox

			try {
				await this.get(this.meta.current_page + 1)
			} catch (e) {
				this.$alert.error('default.error')
			}

			this.busy = false

			await this.$nextTick()

			this.$refs.inbox.scrollTop = scrollTop + 24
		},

		/**
		 * Handle the the infinite scroll load-more button being clicked.
		 *
		 * @return {void}
		 */
		onLoadMoreClick() {
			if (!this.busy) {
				this.autoload = true

				this.fetchNextPage()
			}
		},

		/**
		 * Change the sort direction.
		 *
		 * @return {void}
		 */
		onSort() {
			if (this.sort === 'asc') {
				return (this.sort = 'desc')
			}

			this.sort = 'asc'
		},

		/**
		 * Convert the date string to a unix timestamp.
		 *
		 * @return {Number}
		 */
		toUnix(date, format = DATE_FORMAT) {
			return moment.utc(date, format).unix()
		},

		...mapActions('pages', ['get']),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Pages',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Refresh the loaded page data when the active partner changes.
		 *
		 * @param {Object} to
		 * @param {Object} from
		 * @return {void}
		 */
		$route(to, from) {
			if (to.params.partner !== from.params.partner) {
				this.getData()
			}
		},
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	async created() {
		await this.getData()
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			autoload: true,
			busy: false,
			hasFetchedAllPages: false,
			sort: 'asc',
			today: moment().format('MM/DD/YYYY'),
			yesterday: moment()
				.subtract(1, 'day')
				.format('MM/DD/YYYY'),
		}
	},
}
</script>
